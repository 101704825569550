import "./badge.css";

const Badge = ({ badge, needsShadow = false, onClick = () => {} }) => {
  return (
    <div
      className="badge__container"
      style={{
        backgroundColor: badge["secondary_color"],
        borderColor: badge["primary_color"],
        boxShadow: needsShadow && `0 0 10px ${badge["primary_color"]}`,
      }}
      onClick={onClick}
    >
      <img
        src={badge["image"]}
        alt={`Badge ${badge.name}`}
        width={150}
        height={150}
        loading="lazy"
        unoptimized
      />
    </div>
  );
};

export default Badge;
