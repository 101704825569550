import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AvailableBadges from "./AvailableBadges";
import { TaggedBadges } from "./TaggedBadges";

const BadgeLayout = () => {
  const activityId = useParams().id;
  const [callApi, setCallApi] = useState(true);

  return (
    <>
      <AvailableBadges setCallApi={setCallApi} callApi={callApi} />
      <TaggedBadges setCallApi={setCallApi} callApi={callApi} />
    </>
  );
};

export default BadgeLayout;
