import React, { useEffect, useState } from 'react'
import Card from '../../components/Card'
import { formatDateString } from '../../utils/formatter';
import Table from '../../components/Table/Table';
import { getOrders } from './orderService';

const OrderListing = () => {


    const orderStatusList = [
        "ALL",
        "PAID",
        "PENDING",
        "CANCELLED",
        "FAILED",
        "REFUNDED"
    ]

    const [data, setData] = useState([])

    useEffect(() => {
      const fetchOrders = async () => {
        let tableList = await getOrders()
        setData(tableList)
      }

      fetchOrders()
    }, [])



    const columns = [
        {
          name: "order_id",
          selector: (row) => row.order_id,
          sortable: true,
          cell: (row) => (
            <span
              className="text-truncate tickets-description"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title={row.order_id}
            >
              {row.order_id}
            </span>
          ),
          SortOrder: "desc",
          // width: "200px",
        },
        {
          name: "User name",
          selector: (row) => row.user_name,
          sortable: true,
          // width: "162px",
        },
        {
          name: "User email",
          selector: (row) => row.user_email,
          sortable: true,
          // width: "200px",
        },
        {
          name: "Amount",
          selector: (row) => `${row.amount} INR`,
          sortable: true,
          // width: "200px",
        },
        {
          name: "Course name",
          selector: (row) => row.course_name,
          sortable: true,
          cell: (row) => (
            <span
              className="text-truncate tickets-description"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title={row.course_name}
            >
              {row.course_name}
            </span>
          ),
          // width: "350px",
        },
        {
          name: "Date & Time",
          cell: (row) => (
            <span
              className="text-truncate"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title={formatDateString(row.created_on)}
            >
              {formatDateString(row.created_on)}
            </span>
          ),
          sortable: true,
          // width: "160px",
        },
        {
          name: "Status",
          selector: (row) => row.status,
          cell: (row) => (
            <span className={`status ${row.status.toLowerCase()}`}>
              {row.status}
            </span>
          ),
          sortable: true,
          width: "160px !important",
        },
        // {
        //   name: "Action",
        //   cell: (row) => {
        //     return row.status === "scheduled" || row.status === "rescheduled" ? (
        //       // bootstrap dropdown containing join, cancel and reschedule
        //       // <select
        //       //   className="form-select ticket-action-select"
        //       //   aria-label="Action Select"
        //       //   value={selectedValues[row.id]}
        //       //   onChange={(e) => handleChange(e, row)}
        //       // >
        //       //   <option defaultValue>Select Action</option>
        //       //   <option value="join">Join</option>
        //       //   <option value="reschedule">Reschedule</option>
        //       //   <option value="cancel">Cancel</option>
        //       // </select>
        //       <DropdownButton
        //         dropDownBtnOptions={[
        //           {
        //             label: "Join",
        //             onClick: () => {
        //               dispatch(setSelectedTicket(row));
        //               window.open(
        //                 row.meeting_link,
        //                 "_blank",
        //                 "noopener,noreferrer"
        //               );
        //             },
        //           },
        //           {
        //             label: "Reschedule",
        //             onClick: () => {
        //               dispatch(setSelectedTicket(row));
        //               dispatch(openModal("reschedule_modal"));
        //             },
        //           },
        //           {
        //             label: "Cancel",
        //             onClick: () => {
        //               dispatch(setSelectedTicket(row));
        //               dispatch(openModal("cancel_modal"));
        //             },
        //           },
        //         ]}
        //       />
        //     ) : null;
        //   },
        //   width: "160px",
        // },
      ];




      const onRowClick = (row) => {
          console.log("row clicked")
        };



  return (
    <div className='order-listing'>
        <Card>
        <Table
          entity="orders"
          data={data}
          columns={columns}
          statusList={orderStatusList}
          hasAddBtn={false}
          onRowClick={onRowClick}
          isSupportTickets={true}
          // isServerSidePagination
          // setPage={setPage}
          // setRowsPerPage={setRowsPerPage}
          // paginationTotalRows={totalRows}
        />
        </Card>
    </div>
  )
}

export default OrderListing