import axios from "axios"

import { DEV_BASE_URL } from "../../../utils/constants/routes"
import { generateThumbnailVideo } from "../../../utils/thumbnailGenerator"

export const uploadActivityMedia = async (file, activity_id, media_category, order) => {
    console.log(activity_id)
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.post(`${DEV_BASE_URL}/media/upload`,
        formData,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            },
            params : {
                "activity_id" : activity_id,
                'media_category' : media_category,
                'order' : order,
                'app_media_id' : `${activity_id}_${Date.now()}`
            }
        }
    )
    return response.data
}

export const uploadThumbnail = async (blob, media_id) => {
    const formData = new  FormData()
    const thumbnailFile = new File([blob], `thumbnail-${media_id}.jpg`, { type: 'image/jpeg' });
    formData.append('file', thumbnailFile);

    const response = await axios.post(`${DEV_BASE_URL}/api/v1/media/upload/thumbnail/${media_id}`,
        formData,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data
}

export const getQuestions = async (activity_id, media_category) => {
    console.log(activity_id, media_category)
    try {
        const response = await axios.get(`${DEV_BASE_URL}/api/v1/media/getQuestions`, {
            params: { activity_id, media_category },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        let api_data = response.data
        console.log(api_data)
        if (api_data.status) {
            const formattedData = api_data.data.map(val => ({
                label: val.question,
                value: val.id
            }));
            console.log(formattedData)
            return formattedData; // Returning formatted array
        }
    } catch (error) {
        console.error("Error fetching questions:", error);
        throw error;
    }
};


export const uploadMedia = async (activity_id, user_id, question_id, file) => {
    try {
        let media_category = ""
        if(file.type.startsWith('video/')) media_category = "video"
        else if(file.type.startsWith('audio/')) media_category = "audio"
        else if(file.type.startWith('image/')) media_category = "image"

        let fileToUpload = file

        const formData = new FormData();
        formData.append("file", fileToUpload);

        const response = await axios.post(
            `${DEV_BASE_URL}/media/viva/upload`,
            formData,
            {
                params: {
                    media_category: media_category,
                    activity_id: activity_id,
                    user_id: user_id,
                    question_id: question_id,
                    app_media_id : `${activity_id}_${Date.now()}`
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`, 
                    "Content-Type": "multipart/form-data"
                }
            }
        );

        console.log("Upload successful:", response.data.data);
        if( media_category == "video"){
            let thumbnail = await generateThumbnailVideo(fileToUpload)
            if(thumbnail){
                const res = await uploadThumbnail(thumbnail, response.data.data["media_id "])
                console.log(res)
            }
        }
        return response.data;
    } catch (error) {
        console.error("Error uploading media:", error);
        throw error;
    }
};



