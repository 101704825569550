import React, { useState, useRef } from "react";
import "./multiselect.scss"

const CustomDropdown = ({ dropdownList, selectedValues, setSelectedValues, selectAll, setSelectAll, title }) => {
  const [isOpen, setIsOpen] = useState(false);
//   const [selectedValues, setselectedValues] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);
  const dropdownRef = useRef();

  console.log(dropdownList, selectedValues, selectAll, title);

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionChange = (value) => {
    if (value === "select-all") {
      if (selectAll) {
        setSelectedValues([]); // Deselect all
        setSelectAll(false);
      } else {
        const allIds = dropdownList.map((student) => student.value);
        setSelectedValues(allIds); // Select all
        setSelectAll(true);
      }
    } else {
      let updatedIds = [...selectedValues];
      if (updatedIds.includes(value)) {
        updatedIds = updatedIds.filter((id) => id !== value);
      } else {
        updatedIds.push(value);
      }

      if (updatedIds.length === dropdownList.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }

      setSelectedValues(updatedIds);
    }
  };

  const getDisplayText = () => {
    if (selectAll) {
      return `All ${title} Selected`;
    }
    if (selectedValues.length === 0) {
      return `Select ${title}`;
    }
    if (selectedValues.length <= 8) {
      return selectedValues
        .map((id) => dropdownList.find((student) => student.value === id)?.label)
        .join(", ");
    }
    const firstTwoNames = selectedValues
      .slice(0, 2)
      .map((id) => dropdownList.find((student) => student.value === id)?.label);
    return `${firstTwoNames.join(", ")} +${selectedValues.length - 2} more`;
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className={`dropdown-input ${isOpen ? "open" : ""}`}
        onClick={handleToggleDropdown}
      >
        {getDisplayText()}
      </div>
      {isOpen && (
        <div className="dropdown-options">
          <div className="dropdown-option">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={() => handleOptionChange("select-all")}
            />
            <label>Select All</label>
          </div>
          {dropdownList.length > 0 ? (
            dropdownList.map((student) => (
              <div className="dropdown-option" key={student.value}>
                <input
                  type="checkbox"
                  checked={selectedValues.includes(student.value)}
                  onChange={() => handleOptionChange(student.value)}
                />
                <label>{student.label}</label>
              </div>
            ))
          ) : (
            <div className="no-options">No options available</div>
          )}
          {dropdownList.length > 5 && (
            <div className="scroll-indicator">Scroll for more...</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
