import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addActivityQuestions,
  getActivityQuestions,
  updateActivityQuestions,
} from "../../../activityMasterService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setActivityQuestionsEditable,
  setAddNewActivityQuestions,
} from "../../../activityMasterSlice";

const useActivityQuestions = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "success",
    onClose: () => {},
  });

  const isEditable = useSelector(
    (state) => state.activityMaster.isActivityQuestionsEditable
  );
  const isAddNewActivity = useSelector(
    (state) => state.activityMaster.isAddNewActivity
  );

  const fetchActivityQuestions = async (id) => {
    try {
      const data = await getActivityQuestions(id, "activity");
      if (data.status) {
        if (data.data.length === 0) {
          dispatch(setAddNewActivityQuestions(true));
          if (isEditable)
            setValues([
              {
                question: "",
                id: "new_1",
              },
            ]);
        } else {
          dispatch(setAddNewActivityQuestions(false));
          setValues((prev) => [...data.data]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onQuestionFormSubmit = async (e) => {
    e.preventDefault();
    if (isAddNewActivityQuestions) {
      let newQuestionsToSend = [];
      values.forEach((value) => {
        newQuestionsToSend.push(value.question);
      });
      try {
        const response = await addActivityQuestions(
          newQuestionsToSend,
          id,
          "activity"
        );
        if (response.status) {
          dispatch(setAddNewActivityQuestions(false));
          fetchActivityQuestions(id);
          setAlert({
            show: true,
            message: "Questions added successfully",
            type: "success",
            onClose: () => {
              setAlert({
                show: false,
                message: "",
                type: "success",
              });
            },
          });
        }
      } catch (error) {
        console.log(error);
        setAlert({
          show: true,
          message: error.message,
          type: "error",
          onClose: () => {
            setAlert({
              show: false,
              message: "",
              type: "success",
            });
          },
        });
      }
    } else {
      let newQuestionsToSend = [];
      values.forEach((value) => {
        newQuestionsToSend.push({
          id: typeof value.id === "string" ? null : value.id,
          question: value.question,
        });
      });
      try {
        const response = await updateActivityQuestions(
          newQuestionsToSend,
          id,
          "activity"
        );
        if (response.status) {
          dispatch(setAddNewActivityQuestions(false));
          fetchActivityQuestions(id);
          setAlert({
            show: true,
            message: "Questions updated successfully",
            type: "success",
            onClose: () => {
              setAlert({
                show: false,
                message: "",
                type: "success",
              });
            },
          });
        }
      } catch (error) {
        console.log(error);
        setAlert({
          show: true,
          message: error.message,
          type: "error",
          onClose: () => {
            setAlert({
              show: false,
              message: "",
              type: "success",
            });
          },
        });
      }
    }
  };

  useEffect(() => {
    if (id != "add") fetchActivityQuestions(id);
  }, []);

  const isAddNewActivityQuestions = useSelector(
    (state) => state.activityMaster.isAddNewActivityQuestions
  );

  return [
    values,
    setValues,
    isEditable,
    isAddNewActivityQuestions,
    onQuestionFormSubmit,
    alert,
    setAlert,
    isAddNewActivity,
  ];
};

export default useActivityQuestions;
