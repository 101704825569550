// src/features/activity/activityList.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActivity } from "./activitySlice";
import { activityDetails, get_media_count } from "./activityService";
import "./activityList.scss";
import Card from "../../components/Card";
import Table from "../../components/Table/Table";
import Button from "../../components/Button";
import AddActivityModal from "./addActivityModal/AddActivityModal";
import { EditLogo } from "../../assets/images/svg";
import EditActivityModal from "./addActivityModal/EditActivityModal";
import { useNavigate } from "react-router-dom";
import { closeModal, openModal } from "../../components/Modal/ModalSlice";
import Modal from "../../components/Modal/Modal";
import FormInput from "../../components/Form/FormInput";
import { getGradeDropdown, getSchoolDropdown } from "./addActivityModal/addActivityModalService";

const ActivityList = () => {
  console.log("inside activity list page");
  const token = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [callApi, setCallApi] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivities = async () => {
      const data = await activityDetails(token);
      dispatch(setActivity(data));
    };

    fetchActivities();
    setCallApi(true);
  }, [dispatch, token, callApi]);

  const activities = useSelector((state) => state.activity.activities);
  const user_details = useSelector((state) => state.login.profile)

  const handleEditClick = (activity) => {
    setSelectedActivity(activity);
    setShowEditModal(true);
  };

  const activityStatusList = [
    "ALL",
    "SCHEDULED",
    "INPROGRESS",
    "COMPLETED",
    "SUBMITTED",
    "APPROVED",
    "PUBLISHED",
  ];

  const columns = [
    {
      name: "Class",
      selector: (row) => row.grade_id_rslv,
      sortable: true,
      cell: (row) => `${row.grade_id_rslv}`,
      width: "90px",
    },
    {
      name: "Section",
      selector: (row) => row.section_id_rslv,
      cell: (row) => row.section_id_rslv,
      sortable: true,
      width  :"auto",
      maxWidth : "100px"
    },
    {
      name: "Activity Date",
      selector: (row) => row.start_date,
      sortable: true,
    },
    {
      name: "Activity Name",
      selector: (row) => row.activity_id_rslv,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.activity_id_rslv}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${row.id}`);
          }}
        >
          {row.activity_id_rslv}
        </span>
      ),
    },
    {
      name: "Status updated on",
      selector: (row) => row.modified_on,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
      width: "auto",
      maxWidth: "160px",
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button className="btn" onClick={() => handleEditClick(row)}>
          <EditLogo />
        </button>
      ),
    },
  ];

  if (user_details?.role_id == 2){
    columns.unshift({
      name: "School",
      selector: (row) => row.school_id_rslv, // Replace with the actual field
      sortable: true,
      // cell: (row) => <span>{row.school_id_rslv}</span>, // Customize as needed
      width: "auto",
      maxWidth: "150px",
    });
  }

  const handleShow = () => {
    console.log("trueeee");
    setShowModal(true);
  };

  const onExportClick = () => {
    dispatch(openModal("export_activity_modal"))
  }

  return (
    <div className="activity-page">
      {/* <div className="activity-taskbar mb-2">
        <Button label="Add Activity" onClick={()=>handleShow()} />
      </div> */}
      <Card>
        <Table
          entity="activity"
          data={activities}
          columns={columns}
          statusList={activityStatusList}
          onAddClick={handleShow}
          hasExportButton={true}
          onExportClick={onExportClick}
        />
      </Card>
      {showModal && (
        <AddActivityModal
          displayModal={showModal}
          setShowModal={setShowModal}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      )}
      {showEditModal && (
        <EditActivityModal
          displayModal={showEditModal}
          setShowModal={setShowEditModal}
          activityData={selectedActivity}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      )}

      <ExportActivityModal />
    </div>
  );
};


const ExportActivityModal = () => {
  const [selectedType, setSelectedType] = useState(null)
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [schoolDropdown, setSchoolDropdown] = useState([])
  const [gradeDropdown, setGradeDropdown] = useState([])
  const dispatch = useDispatch()

  const modalType = useSelector((state) => state.modal.modalType);

  const typeDropdown = [
    { label: "Actvity Wise Media Count", value: "1" },
    { label: "Student and Activity Wise Media Count", value: "2" },
  ]

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value)
  }

  const handleSchoolChange = async (e) => {
    setSelectedSchool(e.target.value)
    let gradeDropdownData = await getGradeDropdown(localStorage.getItem("token"), e.target.value);
    console.log(gradeDropdownData)
    setGradeDropdown(gradeDropdownData);

  }

  const handleGradeChange = (selectedGrades) => {
     // Map selected options to an array of staff IDs
     const selectedIds = selectedGrades.map((option) => option.value);
     setSelectedGrades(selectedIds);
  }

  useEffect(() => {
    const fetchSchoolDropdown = async () => {
          try {
            let data = await getSchoolDropdown();
            setSchoolDropdown(data);
          } catch (error) {
            console.error("Error fetching school dropdown:", error);
          }
        };
    
        fetchSchoolDropdown();
  }, [])



  const addActivityFormConfig = [
    {
      id: 1,
      name: "type",
      type: "select",
      placeholder: "Select Type",
      options: typeDropdown,
      required: true,
      onChange: handleTypeChange,
      errorMessage: "Please select a export type",
    },
    {
      id: 2,
      placeholder: "School",
      type: "select",
      options: schoolDropdown,
      required: true,
      onChange: handleSchoolChange,
      errorMessage: "Please select a school activity",
      disabled: schoolDropdown.length === 0,
    },
    {
      id: 3,
      placeholder: "School Grade",
      type: "select",
      multiple: true,
      options: gradeDropdown,
      required: true,
      defaultValues: "",
      onChange: handleGradeChange,
      errorMessage: "Please select a school grade",
      disabled: gradeDropdown.length == 0 || selectedType != 2,
    },
    
  ];

  const handleExportFormSubmit = async (e) => {
    e.preventDefault();
    console.log(selectedType, selectedSchool, selectedGrades)
    let status = await get_media_count(selectedType, selectedSchool, selectedGrades)
    if(status == 200){
      alert("Media downloaded")
      dispatch(closeModal("export_activity_modal"))
    }
    else{
      alert("some error occured")
      dispatch(openModal("export_activity_modal"))
    
    }


  }

  if (modalType == "export_activity_modal"){
    return (
      <Modal 
        title={"Export Activity"}
        onCloseClick={() => {
          dispatch(closeModal())
        }}>
          <div className="export-activity-modal m-3">
            
            <form onSubmit={handleExportFormSubmit}>
  
            {
              addActivityFormConfig.map((item, index) => (
                <FormInput key={item.id} {...item} />
              ))
            }
            <Button 
              type="submit"
              className="d-block mx-auto"
              label={"Download Excel"}
              backgroundColor={'#f6921e'}
              color={'#fff'}
            />
            </form>
  
          </div>
        </Modal>
    )
  }
}


export default ActivityList;
