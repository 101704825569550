import React from "react";
import {
  BookLogo,
  CourseLogo,
  GraduationCapLogo,
  ManufacturingLogo,
  SchoolIcon,
  SettingsLogo,
  StudentsLogo,
  TeacherLogo,
  TicketLogo,
  TimeLogo,
  TVLogo,
} from "../../assets/images/svg";
import { Link, useLocation } from "react-router-dom";
import {
  activity,
  courseMaster,
  courses,
  curriculumMaster,
  masterActivities,
  schoolMaster,
  staffMaster,
  studentMaster,
  tickets,
  ticketDetails,
  orders
} from "../../utils/constants/routes";
import "./index.scss";
import OrderListing from "../../features/order/OrderListing";

const Navigation = () => {
  const location = useLocation(); // Hook to get the current location

  const isActive = (path) => {
    //split location.pathname
    const pathSegments = location.pathname.split("/");
    return `/` + pathSegments[1] === path;
  }; // Function to check active path

  return (
    <div>
      <div className="navigation">
        <div className="logo">
          <TVLogo />
        </div>
        <ul className="menu">
          {/* <li>
            <SchoolIcon/>
            School
          </li> */}
          <li className={isActive(activity) ? "active" : ""}>
            <Link to={`${activity}`}>
              <TimeLogo />
              Activity
            </Link>
          </li>
          <li className={isActive(courses) ? "active" : ""}>
            <Link to={`${courses}`}>
              <CourseLogo />
              Assessments
            </Link>
          </li>
          <ul className="submenu">
            <ManufacturingLogo /> Master
            <li className={`${isActive(curriculumMaster) ? "active" : ""}`}>
              <Link to={`${curriculumMaster}`}> Curriculum</Link>{" "}
            </li>
            <li className={`${isActive(masterActivities) ? "active" : ""}`}>
              <Link to={`${masterActivities}`}> Activity</Link>{" "}
            </li>
            <li className={`${isActive(courseMaster) ? "active" : ""}`}>
              <Link to={`${courseMaster}`}> Course</Link>{" "}
            </li>
          </ul>
          <ul className="submenu">
            <GraduationCapLogo /> School
            <li className={`${isActive(schoolMaster) ? "active" : ""}`}>
              <Link to={`${schoolMaster}`}> School</Link>{" "}
            </li>
            <li className={`${isActive(studentMaster) ? "active" : ""}`}>
              <Link to={`${studentMaster}`}> Student</Link>{" "}
            </li>
            <li className={`${isActive(staffMaster) ? "active" : ""}`}>
              <Link to={`${staffMaster}`}> Staff</Link>{" "}
            </li>
          </ul>

          <li className={isActive(tickets) ? "active" : ""}>
            <Link to={`${tickets}`}>
              <TicketLogo />
              Support Tickets
            </Link>
          </li>

          <li className={isActive(orders) ? "active" : ""}>
            <Link to={`${orders}`}>
            <TicketLogo />
              {/* <OrderListing /> */}
              Orders
            </Link>
          </li>

          {/* <li>
            <BookLogo/>
            Classes
          </li>
          <li>
            <TeacherLogo/>
            Teacher
          </li>
          <li>
            <StudentsLogo/>
            Student
          </li>
          <li>
            <SettingsLogo/>
            Setting
          </li>
          <ul className="submenu">
            <li>Grades</li>
            <li>Sections</li>
            <li>Role</li>
            <li>Access</li>
            <li>TV Team</li>
          </ul> */}
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
