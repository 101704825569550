import React, { useState } from "react";
import "./index.scss";
import { Notifications, Search } from "../../assets/images/svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../components/Modal/ModalSlice";
import { ConfirmationModal } from "../../features/tickets/TicketsList";

const Header = () => {
  const profile = useSelector((state) => state.login.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);

  const showLogoutConfirmationModal = () => {
    dispatch(openModal("logoutModal"));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setShowTooltip(false);
    navigate("/login");
  };

  return (
    <>
      <div className="header">
        <div className="school-logo">
          <img
            src={profile?.school_logo}
            alt="School Logo"
            className="logo-image"
          />
        </div>
        <div className="header-icons">
          <Search />
          <Notifications />

          <div className="dropdown">
            <div
              className="badge rounded-pill text-bg-light dropdown-toggle"
              // type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={profile?.profile_picture || "default-profile-image-url"}
                alt="Profile"
                className="profile-image me-1"
              />
              {profile?.name || "User Name"}
            </div>
            <ul className="dropdown-menu">
              <li
                onClick={showLogoutConfirmationModal}
                className="dropdown-item text-danger"
              >
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
      <LogoutModal handleLogout={handleLogout} />
    </>
  );
};

const LogoutModal = ({ handleLogout }) => {
  const modalType = useSelector((state) => state.modal.modalType);

  if (modalType === "logoutModal") {
    return <ConfirmationModal title="logout" onSubmit={handleLogout} />;
  }
};

export default Header;
