import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../../../components/Button";
import {
  getQuestions,
  uploadActivityMedia,
  uploadMedia,
  uploadThumbnail,
} from "./addActivityMediaService";
import { generateThumbnailVideo } from "../../../utils/thumbnailGenerator";
import "./addActivityMedia.scss";
import { useParams } from "react-router-dom";
import heic2any from "heic2any";
import { CrossLogo } from "../../../assets/images/svg";
import { useSelector } from "react-redux";

function AddActivityMedia({
  currentTab,
  uploadActivityStatus,
  setUploadActivityStatus,
  callActivity,
  setCallActivity,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [overallProgress, setOverallProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false); // New state to manage upload status
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [questions, setQuestions] = useState(false);
  const [studentId, setStudentId] = useState(null);

  console.log(currentTab);

  const onDrop = useCallback((acceptedFiles) => {
    setOverallProgress(0);
    const newFiles = acceptedFiles?.map((file) => file);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const studentList = useSelector((state) => state.activityDetails.studentList);

  const handleStudentChange = (e) => {
    setStudentId(e.target.value);
  };

  useEffect(() => {
    const fetchAPis = async () => {
      const questionList = await getQuestions(id, "viva");
      setQuestions(questionList);
    };
    fetchAPis();
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleReset = () => {
    setSelectedFiles([]);
    setOverallProgress(0);
  };

  const uploadImages = async () => {
    if (currentTab === "viva") {
      setShowModal(true);
    } else {
      setIsUploading(true); // Disable buttons while uploading
      const totalFiles = selectedFiles.length;
      let completedUploads = 0;

      for (const file of selectedFiles) {
        let media_category = "";
        if (currentTab === "logbook") media_category = "logsheet";
        else if (file.type.startsWith("image/")) media_category = "photo";
        else if (file.type.startsWith("video/")) media_category = "video";
        else media_category = "viva";

        let fileToUpload = file;
        if (file.name.endsWith(".heic")) {
          const blob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 1,
          });
          fileToUpload = new File(
            [blob],
            `${file.name.replace(/\.heic$/, ".jpg")}`,
            {
              type: "image/jpeg",
            }
          );
        }

        const data = await uploadActivityMedia(
          fileToUpload,
          id,
          media_category,
          1
        );
        setUploadActivityStatus((prev) => prev + 1);

        completedUploads++;
        setOverallProgress(Math.round((completedUploads / totalFiles) * 100));

        let thumbnail;
        if (file.type.startsWith("video/")) {
          thumbnail = await generateThumbnailVideo(fileToUpload);
        }

        if (thumbnail) {
          await uploadThumbnail(thumbnail, data.data.class_media_id);
        }
      }

      setSelectedFiles([]);
      setOverallProgress(0);
      setIsUploading(false); // Re-enable buttons after upload is complete
    }
  };

  return (
    <div
      className={
        currentTab == "viva"
          ? "upload-media-container dotted-container"
          : "upload-media-container"
      }
    >
      {showModal && (
        <UploadModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleClose={() => setShowModal(false)}
          handleStudentChange={handleStudentChange}
          studentList={studentList}
          questions={questions}
          id={id}
          callActivity={callActivity}
          setCallActivity={setCallActivity}
        />
      )}
      {currentTab != "viva" && (
        <div {...getRootProps()}>
          <div className="upload-media">
            <input {...getInputProps()} />

            <p className="drap-drop-box">
              <br />
              Drag 'n' drop some files here, or click to select files
            </p>
          </div>
        </div>
      )}
      <div
        className={currentTab == "viva" ? "content-wrapper" : "content-wrapper"}
      >
        {currentTab != "viva" && selectedFiles.length > 0 ? (
          <div className="selected-media-container">
            {selectedFiles?.map((file) => (
              <div key={file.name}>
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    width="100"
                    height="100"
                  />
                ) : (
                  <p>{file.name}</p>
                )}
              </div>
            ))}
          </div>
        ) : (
          currentTab != "viva" && <p>No files selected yet.</p>
        )}

        {currentTab != "viva" && selectedFiles.length > 0 && (
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${overallProgress}%` }}
            />
          </div>
        )}
        {currentTab != "viva" && <span>{overallProgress}%</span>}

        {currentTab == "viva" && (
          <p>Click on "Upload" button to select media</p>
        )}

        <div className="button-wrapper">
          <Button
            label="Upload"
            backgroundColor="#F6921E"
            onClick={uploadImages}
            disabled={
              isUploading || (selectedFiles.length == 0 && currentTab != "viva")
            } // Disable during upload
          />
          {currentTab != "viva" && (
            <Button
              label="Reset"
              onClick={handleReset}
              disabled={isUploading || selectedFiles.length == 0} // Disable during upload
            />
          )}
        </div>
      </div>
    </div>
  );
}

const UploadModal = ({
  showModal,
  setShowModal,
  handleClose,
  studentList,
  questions,
  id,
  callActivity,
  setCallActivity,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [studentId, setStudentId] = useState(null);
  const [questionId, setQuestionId] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // setOverallProgress(0)
    // const newFiles = acceptedFiles?.map(file => file);
    // setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    // Filter only audio files
    const mediaFiles = acceptedFiles.filter((file) =>
      file.type.startsWith("audio/") || file.type.startsWith("video/")
    );

  if (mediaFiles.length === 0) {
    alert("Only audio & video files are allowed!");
    return;
  }
    setSelectedFiles(mediaFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop ,accept: "audio/*, video/*", });

  const handleStudentChange = (e) => {
    setStudentId(e.target.value);
  };

  const handleQuestionChange = (e) => {
    setQuestionId(e.target.value);
  };

  const handleSubmit = async () => {
    console.log("handle submit");
    console.log(selectedFiles);
    // const data = await uploadMedia(id, studentId, questionId, selectedFiles[0]);
    // console.log(data);
    const uploadPromises = selectedFiles.map((file) =>
      uploadMedia(id, studentId, questionId, file)
    );

    const responses = await Promise.all(uploadPromises); // Upload all files concurrently
    console.log("Upload responses:", responses);
    setQuestionId(null);
    setStudentId(null);
    setSelectedFiles([]);
    setCallActivity(!callActivity);
    handleClose();
  };

  return (
    <div
      className={
        showModal
          ? "modal show base-modal fade d-block upload-modal"
          : "modal fade d-block upload-modal"
      }
      style={{ backgroundColor: "rgba(0,0,0,.5)" }}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Upl4</h5>
            <div className="close-modal" onClick={handleClose}>
              <CrossLogo />
            </div>
          </div>
          <div className="modal-body reject-modal-body">
            <select
              className="form-select mb-2"
              aria-label="Select student"
              onChange={handleStudentChange}
              value={studentId ? studentId : ""}
            >
              <option selected>Students</option>
              {studentList?.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
            <select
              className="form-select mb-2"
              aria-label="Select student"
              onChange={handleQuestionChange}
              value={questionId ? questionId : ""}
            >
              <option selected>Questions</option>
              {questions?.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
              <option value={""}>Ask your own question</option>
            </select>

            <div {...getRootProps()}>
              <div className="upload-media">
                <input {...getInputProps()} />

                <p className="drap-drop-box">
                  <br />
                  Drag 'n' drop some files here, or click to select files
                </p>
              </div>
            </div>

            <div className="content-wrapper">
              {selectedFiles.length > 0 ? (
                <div className="selected-media-container">
                  {selectedFiles?.map((file) => (
                    <div key={file.name}>
                      {file.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          width="100%"
                          height="250"
                        />
                      ) : file.type.startsWith("video/") ? (
                        <video width="100%" height="250" controls>
                          <source
                            src={URL.createObjectURL(file)}
                            type={file.type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : file.type.startsWith("audio/") ? (
                        <audio controls>
                          <source
                            src={URL.createObjectURL(file)}
                            type={file.type}
                          />
                          Your browser does not support the audio element.
                        </audio>
                      ) : (
                        <p>{file.name}</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p>No files selected yet.</p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              label="Cancel"
              backgroundColor="white"
              textColor="#EA983E"
              onClick={() => handleClose()}
              className="cancel"
            />
            <Button
              label="Upload"
              backgroundColor="#EA983E"
              textColor="white"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddActivityMedia;
