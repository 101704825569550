import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activities: [],
  isActivityEditable: false,
  isFieldsDisabled: true,
  isAddNewActivity: false,
  isAddNewActivityQuestions: false,
  isActivityQuestionsEditable: false,
};

const activityMasterSlice = createSlice({
  name: "activityMaster",
  initialState,
  reducers: {
    setMasterActivities: (state, action) => {
      state.activities = action.payload;
    },
    setActivityEditable: (state, action) => {
      state.isActivityEditable = action.payload;
    },
    setFieldsDisable: (state, action) => {
      state.isFieldsDisabled = action.payload;
    },
    setAddNewActivity: (state, action) => {
      state.isAddNewActivity = action.payload;
    },
    setAddNewActivityQuestions: (state, action) => {
      state.isAddNewActivityQuestions = action.payload;
    },
    setActivityQuestionsEditable: (state, action) => {
      state.isActivityQuestionsEditable = action.payload;
    },
  },
});

export const {
  setMasterActivities,
  setActivityEditable,
  setFieldsDisable,
  setAddNewActivity,
  setAddNewActivityQuestions,
  setActivityQuestionsEditable,
} = activityMasterSlice.actions;

export default activityMasterSlice.reducer;
