import React, { useEffect } from "react";
import Layout from "./Layout/MainLayout";
import { useDispatch } from "react-redux";
import {
  Navigate,
  redirect,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ActivityList from "./features/activity/activityList";
import {
  activity,
  activityDetail,
  courseDetails,
  courseMaster,
  courseMasterAdd,
  courseMasterDetails,
  courses,
  curriculumDetails,
  curriculumMaster,
  home,
  login,
  masterActivities,
  masterActivityAdd,
  schoolMaster,
  schoolMasterAdd,
  staffMaster,
  studentMaster,
  tickets,
  ticketDetails,
  orders
} from "./utils/constants/routes";
import { fetchUserProfile } from "./features/login/loginService";
import { setProfile } from "./features/login/loginSlice";
import Login from "./features/login";
import ActivityDetailsPage from "./features/activity/activityDetails/activityDetails";
import CourseList from "./features/course/courseList";
import CourseDetails from "./features/course/courseDetails/courseDetails";
import ActivityMasterList from "./features/master/activity/List";
import AddActivity from "./features/master/activity/AddActivity/AddActivity";
import CurriculumList from "./features/master/curriculum/CurriculumList";
import CurriculumDetails from "./features/master/curriculum/curriculumDetails/CurriculumDetails";
import {
  SchoolMasterList,
  StaffMasterList,
  StudentMasterList,
  AddSchool,
} from "./features/schools";
import CourseMasterList from "./features/master/course/CourseMasterList";
import AddCourse from "./features/master/course/AddCourse/AddCourse";
import TicketsList from "./features/tickets/TicketsList";
import TicketDetails from "./features/tickets/ticketDetails/TicketDetails";
import OrderListing from "./features/order/OrderListing";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = !!localStorage.getItem("token");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile(token)
        .then((profileData) => {
          if (profileData.status) {
            dispatch(setProfile(profileData.data));
          } else {
            localStorage.removeItem("token");
            navigate(login);
          }
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem("token");
          navigate(login);
        });
    } else {
      navigate(login);
    }
  }, [dispatch, navigate]);

  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <Routes>
            <Route path={activity} Component={ActivityList} />
            <Route path={home} Component={ActivityList} />
            <Route path={activityDetail} Component={ActivityDetailsPage} />
            <Route path={courses} Component={CourseList} />
            <Route path={courseDetails} Component={CourseDetails} />
            <Route path={masterActivities} Component={ActivityMasterList} />
            <Route path={masterActivityAdd} Component={AddActivity} />
            <Route path={curriculumMaster} Component={CurriculumList} />
            <Route path={curriculumDetails} Component={CurriculumDetails} />
            <Route path={schoolMaster} Component={SchoolMasterList} />
            <Route path={schoolMasterAdd} Component={AddSchool} />
            <Route path={staffMaster} Component={StaffMasterList} />
            <Route path={studentMaster} Component={StudentMasterList} />
            <Route path={courseMaster} Component={CourseMasterList} />
            <Route path={courseMasterAdd} Component={AddCourse} />
            <Route path={tickets} Component={TicketsList} />
            <Route path={ticketDetails} Component={TicketDetails} />
            <Route path={orders} Component={OrderListing} />  
            {/* <Route path={courseMasterDetails} Component={CourseMasterDetail}/> */}
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path={login} Component={Login} />
          <Route path="*" element={<Navigate to={login} />} />
        </Routes>
      )}
    </>
  );
};

export default App;
