export const login = "/login";
export const activity = "/activity";
export const home = "/";
export const activityDetail = "/activity/:id";
export const courses = "/assessments";
export const courseDetails = "/assessments/:id";
export const masterActivities = "/activity-master";
export const masterActivityAdd = "/activity-master/:id";
export const curriculumMaster = "/curriculum-master";
export const curriculumDetails = "/curriculum-master/:id";
export const courseMaster = "/course-master";
export const courseMasterAdd = "/course-master/:id";
export const schoolMaster = "/school-master";
export const schoolMasterAdd = "/school-master/:id";
export const studentMaster = "/student-master";
export const studentMasterAdd = "/student-master/:id";
export const staffMaster = "/staff-master";
export const courseMasterDetails = "/curriculum-master/:id";
export const tickets = "/tickets";
export const ticketDetails = "/tickets/:id";
export const orders = "/orders";

// BASE_URL
export const DEV_BASE_URL = "https://api.uat.tinkervillage.io";
// export const DEV_BASE_URL = "https://api.tinkervillage.io";
// export const DEV_BASE_URL = "https://api.tinkervillage.io";
// export const DEV_BASE_URL = "http://13.127.99.222:5002";

// export const DEV_BASE_URL = "http://13.232.136.97:5002";
// export const DEV_BASE_URL = "https://api.tinkervillage.io";

// export const DEV_BASE_URL = "http://localhost:5000";
