import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import SubHeader from "../SubHeader";
import "./index.scss";
import { useSelector } from "react-redux";
import loadingGif from "../../assets/images/media/Loading_2.gif"

const Layout = ({ children }) => {

  let isDownloading = useSelector(
      (state) => state.activityDetails.isDownloading
    )

  let progress = useSelector(
      (state) => state.activityDetails.downloadProgress
    )

  return (
    <main className="main-layout">
       <div className="row m-0 p-0 inner-main-layout">
         <div className="col-2 p-0">
          <Navigation />
         </div>
        <div className="col-10 p-0">
           <Header />
           <SubHeader />
           <div className="page-content">
             {children}
          </div>
         </div>
         {isDownloading && <div className="download-indicator-container gap-1">
         <div className="me9-2">{progress == 0 ? `Preparing to Download` : `Downloading ${progress}%`} </div>
         {progress == 0 && <img width="30px" height="30px" src={loadingGif} alt="Loading..."/>}
         </div>}
 

       </div>
    </main>
   );
 };

 export default Layout;