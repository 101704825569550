import axios from "axios";
import qs from "qs"; // Import query-string serializer
import { DEV_BASE_URL } from "../../utils/constants/routes";

export const activityDetails = async (token) => {
    console.log(token)
    const response = await axios.get(`${DEV_BASE_URL}/api/v1/dashboard/class_list`, {
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    });

    console.log(response.data.data.classes)

    return response.data.data.classes
}

export const get_media_count = async (type, school_id, grade_ids) => {
    let parameters ={}
    let url = ""
    if( type == "1"){
        parameters = {"school_id" : school_id}
        url = `${DEV_BASE_URL}/api/v1/data_download/get_media_count_by_activity_wise`
    }
    else{
        parameters = {"grade_ids" : grade_ids}
        url = `${DEV_BASE_URL}/api/v1/data_download/get_media_count_by_student_wise`
    }
    try {
        const response = await axios.get(url, 
        {
            params: parameters,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }), // Removes []
            responseType: "blob", // Important for binary data (Excel)
        });

        if (response.status === 200) {
            // Create a URL for the downloaded Excel file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            let file_name = type == "1" ? "activity_media_count.xlsx" : "student_media_count.xlsx"
            link.href = url;
            link.setAttribute("download", file_name); // Set file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return response.status

    } catch (error) {
        if (error.response) {
          return error.response.status; // Return the actual error status code
        }
        return 500; // Default to server error
      }
};
