import { useEffect, useState } from "react";
import Badge from "./Badge/Badge";
import useBadge from "./hooks/useBadge";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../components/Modal/ModalSlice";
import Modal from "../../../../components/Modal/Modal";
import { useSelector } from "react-redux";
import Button from "../../../../components/Button";
import { useParams } from "react-router-dom";

export const TaggedBadges = ({ callApi, setCallApi }) => {
  const { taggedBadgesWithDetails } = useBadge(callApi);
  const [filteredTaggedBadges, setFilteredTaggedBadges] = useState([]);

  useEffect(() => {
    setFilteredTaggedBadges(taggedBadgesWithDetails);
  }, [taggedBadgesWithDetails, callApi]);

  // const tempUsers = Array.from({ length: 20 }, (_, i) => ({
  //   id: i + 1,
  //   first_name: `User ${i + 1}`,
  //   last_name: `Last Name ${i + 1}`,
  //   profile_photo_url: `https://picsum.photos/id/${i + 1}/200/200`,
  // }));

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filteredBadges = taggedBadgesWithDetails.filter((badge_tag_group) => {
      return (
        badge_tag_group.badge.name.toLowerCase().includes(searchQuery) ||
        badge_tag_group.tagged_users.some((user) =>
          user.first_name.toLowerCase().includes(searchQuery)
        )
      );
    });
    console.log(filteredBadges);
    setFilteredTaggedBadges(filteredBadges);
  };

  return (
    <div className="mb-5">
      <div className="d-flex justify-content-between mb-3">
        <h5>Tagged Badges</h5>
        <input
          type="text"
          className="form-control w-25"
          placeholder="Search by badge name or student"
          onChange={handleSearch}
        />
      </div>

      <TaggedBadgesAccordion
        taggedBadgesWithDetails={filteredTaggedBadges}
        callApi={callApi}
        setCallApi={setCallApi}
      />
    </div>
  );
};

const TaggedBadgesAccordion = ({
  taggedBadgesWithDetails,
  setCallApi,
  callApi,
}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close
  };

  return (
    <div className="tagged-badges ">
      <div className="tagged-badges-list row">
        {taggedBadgesWithDetails?.map((badgeWithDetail, index) => (
          <div key={index} className="accordion-item mb-2 col-4">
            {/* Header */}
            <div
              style={{
                backgroundColor: badgeWithDetail.badge.secondary_color,
                cursor: "pointer",
              }}
            >
              <TaggedBadgeHeader
                badgeWithDetail={badgeWithDetail}
                className="accordion-header p-2 d-flex justify-content-between align-items-center cursor-pointer"
                onClick={() => toggleAccordion(index)}
                openIndex={openIndex}
                index={index}
              />
            </div>

            {/* Content (UserList) */}
            {openIndex === index && (
              <div
                className="accordion-content p-3"
                style={{
                  border: "1px solid black",
                  borderTop: "none",
                  borderBottomLeftRadius: "1rem",
                  borderBottomRightRadius: "1rem",
                }}
              >
                <UserList
                  badge={badgeWithDetail.badge}
                  users={badgeWithDetail.tagged_users}
                  callApi={callApi}
                  setCallApi={setCallApi}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const TaggedBadgeHeader = ({
  badgeWithDetail,
  className,
  onClick,
  openIndex,
  index,
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        backgroundColor: badgeWithDetail.badge.secondary_color,
        border: `2px solid ${badgeWithDetail.badge.primary_color}`,
        borderRadius: "1rem",
        cursor: "pointer",
      }}
    >
      <div className={`d-flex gap-3 text-center`}>
        <Badge
          key={badgeWithDetail.badge.id}
          badge={badgeWithDetail.badge}
          needsShadow
        />
        <div className="d-flex flex-column justify-content-around">
          <BadgeName name={badgeWithDetail.badge.name} />
          <TaggedCount badgeWithDetail={badgeWithDetail} />
        </div>
      </div>
      <span>{openIndex === index ? "▲" : "▼"}</span>
    </div>
  );
};

export const BadgeName = ({ name }) => {
  return <div className="badge-name fs-5 fw-bold ">{name}</div>;
};

const TaggedCount = ({ badgeWithDetail }) => {
  return (
    <div
      className="tag-count"
      style={{
        fontSize: "16px",
        color: badgeWithDetail.badge.primary_color,
        outline: `2px solid ${badgeWithDetail.badge.primary_color}`,
        boxShadow: `0 0 10px ${badgeWithDetail.badge.primary_color}`,
        borderRadius: ".5rem",
      }}
    >
      {badgeWithDetail?.tagged_users?.length}
      {badgeWithDetail?.tagged_users?.length > 1 ? " Students" : " Student"}
    </div>
  );
};

const UserList = ({ badge, users, callApi, setCallApi }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const currentStatus = useSelector(
    (state) => state.activityDetails.currentStatus
  );

  let isTagDisabled =
    currentStatus === "Publish" ||
    currentStatus === "Finish" ||
    currentStatus === "Approve";

  const handleUserClick = (user) => {
    if (isTagDisabled) return;
    setSelectedUser(user);
    dispatch(openModal("untagBadgeModal"));
  };

  return (
    <div className="user-list d-flex gap-3 flex-wrap ">
      {users?.map((user) => (
        <div
          className="user_profile_pic d-flex flex-column align-items-center text-center"
          onClick={() => handleUserClick(user)}
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src={user.profile_photo_url}
            alt={user.first_name + " pfp"}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <span key={user.id}>{user.first_name + " " + user.last_name}</span>
        </div>
      ))}
      <UntagBadgeModal
        badge={badge}
        user={selectedUser}
        callApi={callApi}
        setCallApi={setCallApi}
      />
    </div>
  );
};

const UntagBadgeModal = ({ user, badge, callApi, setCallApi }) => {
  const modalType = useSelector((state) => state.modal.modalType);
  const dispatch = useDispatch();
  const activityId = useParams().id;
  const { untagUsersFromBadge } = useBadge();

  const handleUntagUser = async () => {
    console.log(user.user_id, activityId, badge.id);
    const response = await untagUsersFromBadge(activityId, badge.id, [
      user.user_id,
    ]);
    if (response.status) {
      alert(`User: ${user.first_name} untagged from the badge: ${badge.name} `);
      setCallApi(!callApi);
      dispatch(closeModal());
    } else {
      alert(`Some error occurred: ${response.message}`);
      dispatch(closeModal());
    }
  };

  if (modalType == "untagBadgeModal") {
    return (
      <Modal title="Untag Badge" className={"modal-content2-sm"}>
        <div className="untag-user mb-3">
          Untag {user.first_name} from this Badge ?
        </div>
        <div className="d-flex mb-3 justify-content-center gap-2">
          <Button
            label={"Cancel"}
            className="btn btn-outline"
            onClick={() => dispatch(closeModal())}
          />
          <Button
            label={"Untag"}
            className="btn"
            backgroundColor={"#f6921e"}
            textColor={"white"}
            onClick={() => handleUntagUser()}
          />
        </div>
      </Modal>
    );
  }
};
