import Badge from "./Badge/Badge";
import useBadge from "./hooks/useBadge";
import "./availableBadges.css";
import { useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../components/Modal/ModalSlice";
import { useSelector } from "react-redux";
import { BadgeName } from "./TaggedBadges";
import FormInput from "../../../../components/Form/FormInput";
import Button from "../../../../components/Button";
import { useParams } from "react-router-dom";

const AvailableBadges = ({ setCallApi, callApi }) => {
  const { badges } = useBadge(callApi);
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const dispatch = useDispatch();
  const currentStatus = useSelector(
    (state) => state.activityDetails.currentStatus
  );

  let isTagDisabled =
    currentStatus === "Publish" ||
    currentStatus === "Finish" ||
    currentStatus === "Approve";

  // Sync filteredBadges when badges update
  useEffect(() => {
    setFilteredBadges(badges);
  }, [badges, callApi]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filteredBadges = badges.filter((badge) => {
      return badge.name.toLowerCase().includes(searchQuery);
    });
    console.log(filteredBadges);
    setFilteredBadges(filteredBadges);
  };

  const openTagBadgeModal = (badge) => {
    console.log(currentStatus, isTagDisabled);
    if (isTagDisabled) return;
    setSelectedBadge(badge);
    dispatch(openModal("tagBadgeModal"));
  };

  return (
    <div className="available-badges border border-1 rounded p-3 mb-4">
      <div className="d-flex justify-content-between mb-3">
        <h5>Available Badges (Click to tag)</h5>
        <input
          type="text"
          className="form-control w-25"
          placeholder="Search by badge name"
          onChange={handleSearch}
        />
      </div>
      <div className="badges-list d-flex gap-3 flex-wrap">
        {filteredBadges?.map((badge) => (
          <Badge
            key={badge.id}
            badge={badge}
            onClick={() => openTagBadgeModal(badge)}
          />
        ))}
      </div>
      <TagBadgeModal
        badge={selectedBadge}
        setCallApi={setCallApi}
        callApi={callApi}
      />
    </div>
  );
};

export const TagBadgeModal = ({ badge, setCallApi, callApi }) => {
  const modalType = useSelector((state) => state.modal.modalType);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const studentList = useSelector((state) => state.activityDetails.studentList);
  const activityId = useParams().id;
  const { tagUsersToBadge } = useBadge();
  const dispatch = useDispatch();

  const handleStudentChange = (selectedStudents) => {
    // Map selected options to an array of staff IDs
    const selectedIds = selectedStudents.map((option) => option.value);
    setSelectedStudents(selectedIds);
  };

  const formConfig = [
    {
      id: 1,
      name: "type",
      type: "select",
      placeholder: "Select Student(s)",
      options: studentList,
      required: true,
      multiple: true,
      onChange: handleStudentChange,
      errorMessage: "Please select students",
    },
  ];

  const handleTagBadge = async (e) => {
    e.preventDefault();
    const response = await tagUsersToBadge(
      activityId,
      badge.id,
      selectedStudents
    );
    if (response.status) {
      alert("Badge tagged successfully");
      dispatch(closeModal());
      setCallApi(!callApi);
    } else {
      alert("Error tagging badge");
      dispatch(closeModal());
    }
  };

  if (modalType === "tagBadgeModal") {
    return (
      <Modal title="Tag Badge" className={"modal-content2-sm"}>
        <div className="d-flex gap-3 py-3">
          <div className="d-flex flex-column gap-3">
            <Badge badge={badge} />
            <BadgeName name={badge.name} />
          </div>
          <div className="student-form flex-grow-1">
            <form
              onSubmit={handleTagBadge}
              className="d-flex flex-column justify-content-between h-100"
            >
              {formConfig.map((item, index) => (
                <FormInput key={item.id} {...item} />
              ))}
              <div className="d-flex gap-2">
                <Button
                  type="button"
                  className="d-block ms-auto"
                  label={"Cancel"}
                  backgroundColor={"white"}
                  color={"#fff"}
                  onClick={() => dispatch(closeModal())}
                />
                <Button
                  type="submit"
                  className=""
                  label={"Tag"}
                  backgroundColor={"#f6921e"}
                  color={"#fff"}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
};

export default AvailableBadges;
