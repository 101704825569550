import axios from "axios";
import React, { act, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DEV_BASE_URL } from "../../../../../utils/constants/routes";

const useBadge = (callApi) => {
  const [badges, setBadges] = useState([]);
  const [taggedBadgesWithDetails, setTaggedBadgesWithDetails] = useState([]);

  const activityId = useParams().id;

  const fetchAllBadgesOfActivity = async (activityId) => {
    try {
      let token = localStorage.getItem("token");
      let url = `${DEV_BASE_URL}/api/v1/badge/get_all_badges_of_activity`;
      const response = await axios.post(
        url,
        {
          activity_id: activityId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBadges(response.data.data.badges);
      return response.data;
    } catch (error) {
      console.error("Error fetching badges:", error);
      return null;
    }
  };

  const fetchTaggedBadgesWithDetails = async (activityId) => {
    try {
      let token = localStorage.getItem("token");
      let url = `${DEV_BASE_URL}/api/v1/badge/get_all_badges_info_and_its_tagged_users_with_details`;
      const response = await axios.post(
        url,
        {
          activity_id: activityId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTaggedBadgesWithDetails(response.data.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching badges:", error);
      return null;
    }
  };

  const tagUsersToBadge = async (activityId, badgeId, userIds) => {
    try {
      let token = localStorage.getItem("token");
      let url = `${DEV_BASE_URL}/api/v1/badge/tag_users_to_badge`;
      const response = await axios.post(
        url,
        {
          activity_id: activityId,
          badge_id: badgeId,
          user_ids: userIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Tagging badge:", error);
      return {
        status: false,
        message: error.message,
      };
    }
  };

  const untagUsersFromBadge = async (activityId, badgeId, userIds) => {
    try {
      let token = localStorage.getItem("token");
      let url = `${DEV_BASE_URL}/api/v1/badge/untag_users_from_badge`;
      const response = await axios.post(
        url,
        {
          activity_id: activityId,
          badge_id: badgeId,
          user_ids: userIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Un-Tagging from badge:", error);
      return {
        status: false,
        message: error.message,
      };
    }
  };

  useEffect(() => {
    fetchAllBadgesOfActivity(activityId);
    fetchTaggedBadgesWithDetails(activityId);
  }, [activityId, callApi]);

  return {
    badges,
    taggedBadgesWithDetails,
    tagUsersToBadge,
    untagUsersFromBadge,
  };
};

export default useBadge;
