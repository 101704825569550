import axios from "axios"
import { DEV_BASE_URL } from "../../utils/constants/routes"

export const getOrders = async (course_id, user_id) => {
    try{
        let params = {}
        if (course_id){
            params["course_id"] = course_id
        }
        if (user_id){
            params["user_id"] = user_id
        }
        const resposne = await axios.get(`${DEV_BASE_URL}/api/v1/razorpay/order/listing`, 
            {
                params : params,
                headers : {
                "Authorization" : `Bearer ${localStorage.getItem("token")}`
            }
        })
        console.log(resposne.data.data)
        if (resposne.data.status){
            return resposne.data.data
        }
        else{
            alert(resposne.data.message)
        }
    } catch (error){
        console.log(error)

    }
    
    
}