import React, { useState } from "react";
import Card from "../../../../../components/Card";
import "./activityQuestions.scss";
import {
  AddLogo,
  DeleteLogo,
  InfoLogo,
} from "../../../../../assets/images/svg";
import useActivityQuestions from "./hooks/useActivityQuestions";
import FormInput from "../../../../../components/Form/FormInput";
import Button from "../../../../../components/Button";
import Alert from "../../../../../components/Alerts/Alert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ActivityQuestions = () => {
  const navigate = useNavigate();
  const [
    values,
    setValues,
    isEditable,
    isAddNewActivityQuestions,
    onQuestionFormSubmit,
    alert,
    setAlert,
    isAddNewActivity,
  ] = useActivityQuestions();

  const onCancelClick = () => {
    navigate(-1);
  };

  return (
    <div className="add__questions--page">
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={alert.onClose}
        />
      )}
      <Card>
        <div className="add__questions--content d-flex flex-column p-3">
          <div
            className="alert alert-primary-tv p-2 d-flex align-items-center"
            role="alert"
          >
            <span className="me-1">
              <InfoLogo />
            </span>
            This questionnaires will help Teachers/ Facilitators in conducting
            seamless activity viva.
          </div>
          <form
            className="flex-grow-1 d-flex flex-column"
            onSubmit={onQuestionFormSubmit}
          >
            <ActivityQuestionsListing
              label="Question"
              values={values}
              setValues={setValues}
              disabled={!isEditable}
              className="d-flex flex-row gap-3 align-items-center col-11"
            />
            <div className="mt-auto ms-auto">
              {isEditable && !isAddNewActivity && (
                <div className="d-flex gap-3 mt-5">
                  <Button
                    label={"CANCEL"}
                    backgroundColor={"transparent"}
                    textColor={"#f6921e"}
                    className="px-5 cancel"
                    onClick={onCancelClick}
                  />
                  <Button
                    label={isAddNewActivityQuestions ? "SAVE" : "UPDATE"}
                    backgroundColor="#f6921e"
                    textColor="white"
                    type="submit"
                    className="px-5"
                    disabled={alert.show}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export const ActivityQuestionsListing = ({
  values,
  setValues,
  disabled,
  ...props
}) => {
  const isAddNewActivity = useSelector(
    (state) => state.activityMaster.isAddNewActivity
  );

  if (values.length === 0)
    return (
      <>
        <p className="text-center">
          {isAddNewActivity
            ? "You will be able to add questions after activity is created!"
            : "No questions added"}
        </p>
      </>
    );
  return (
    <>
      {values.map((questionObj, i) => (
        <div key={`question_${i}`} className="row align-items-center">
          <FormInput
            label={props.label && `${props.label} ${i + 1}`}
            key={`question_${i}`}
            name={`questions_${i}`}
            type="text"
            placeholder="Example: What is the purpose of this activity?"
            value={questionObj.question}
            onChange={(e) => {
              const updatedQuestion = {
                ...questionObj,
                question: e.target.value,
              };
              if (updatedQuestion.id === undefined) updatedQuestion.id = null;

              setValues((prevValues) =>
                prevValues.map((q) =>
                  q.id === questionObj.id ? updatedQuestion : q
                )
              );

              // console.log(values, updatedQuestion);
            }}
            className={props.className}
            required={true}
            disabled={disabled}
          />

          <div
            className={`col-auto ${i !== values.length - 1 ? "opacity-0" : ""}`}
            onClick={() =>
              !disabled &&
              i === values.length - 1 &&
              setValues([...values, { id: `new_${i + 2}`, question: "" }])
            }
          >
            <AddLogo />
          </div>
          {!disabled && values.length > 1 && (
            <div
              className={`col-auto ${
                typeof questionObj.id !== "string" ? "opacity-0" : ""
              }`}
              onClick={() =>
                setValues(values.filter((_, index) => index !== i))
              }
            >
              <DeleteLogo />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ActivityQuestions;
